<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" label="电影名称" name="name">
            <a-input v-model:value="formState.name" placeholder="请输入电影名称"></a-input>
          </a-form-item>
          <a-form-item class="ui-form__item" label="影片编码" name="standardCode">
            <a-input v-model:value="formState.standardCode" placeholder="请输入影片编码"></a-input>
          </a-form-item>
          <a-form-item label="上映时间"  name="onlineTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.onlineTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['film_filmInfo_export']" type="link" style="background: rgb(26, 182, 103); color: white;">导 出</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1950 }">
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'region'">
              {{ record.region ? record.region : '-' }}
            </template>
            <template v-if="column.key === 'description'">
              {{ record.description ? record.description : '-' }}
            </template>
            <template v-if="column.key === 'postUrl'">
              <a-image style="width: 60px;" :src="record.posterUrl"></a-image>
            </template>
            <template v-if="column.key === 'type'">
              <div v-for="item in record.classifyList" :key="item.id">{{ item.name }}</div>
              <div v-if="record.classifyList.length === 0">-</div>
            </template>
            <template v-if="column.key === 'onlineTime'">
              {{ transDateTime(record.onlineTime) }}
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {getFilmInfoList} from "@/service/modules/report";

export default {
  components: {  },
  data() {
    return {
      loading: false,
      searchData: {},
      formState: {

      },
      list: [],
      columns: [
        {
          title: '序号',
          key: 'index',
        },
        {
          title: '影片编码',
          dataIndex: 'standardCode',
        },
        {
          title: '电影名称',
          dataIndex: 'name',
        },
        {
          title: '电影海报',
          key: 'postUrl',
        },
        {
          title: '国家',
          key: 'region',
        },
        {
          title: '时长',
          dataIndex: 'duration',
        },
        {
          title: '类型',
          key: 'type',
        },
        {
          title: '上映时间',
          key: 'onlineTime',
        },
        {
          title: '评分',
          dataIndex: 'score',
        },
        {
          title: '电影简介',
          key: 'description',
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset(){
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch(){
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      if(this.searchData.onlineTime && this.searchData.onlineTime.length === 2) {
        this.searchData.onlineStartTime = this.moment(this.formState.onlineTime[0].startOf('day')).unix();
        this.searchData.onlineEndTime = this.moment(this.formState.onlineTime[1].endOf('day')).unix();
      }
      this.loading = true;
      try {
        let ret = await getFilmInfoList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
